const createBackendPayload = (consentTemplate, newVersion) => {
  const processedConsentText = newVersion.consentText
    .replace(/<\/?p[^>]*>/g, "")
    .replace(/<br\s*\/?>/g, "")
    .replace(/&nbsp;/g, "")
    .replace(/&amp;/g, "&")
    .trim();

  const processedNoticeText = newVersion.noticeText
    .replace(/<[^>]+>/g, "")
    .trim();

  return {
    consentTemplateType: {
      consentTemplateTypeId: consentTemplate.consentTemplateTypeId,
      description:
        consentTemplate?.description || consentTemplate.consentTemplateType,
    },
    country: {
      countryCode: consentTemplate.countryCode,
      countryName: consentTemplate.countryName || consentTemplate.country,
    },
    language: {
      languageCode: consentTemplate.languageCode,
      languageName: consentTemplate.languageName || consentTemplate.language,
    },
    templateVersionNumber: newVersion.templateVersionNumber,
    consentText:
      processedConsentText.length > 0
        ? `<p class='crs-consent'>${processedConsentText}</p>`
        : null,
    noticeText:
      processedNoticeText.length > 0
        ? `<div class='crs-notice'>${newVersion.noticeText}</div>`
        : null,
  };
};

export default createBackendPayload;
